import { PACKAGE_STATUS_TRANSLATE } from '../constants/packageStatus'
import { shortDate, getFormattedDate, getTimeFromFormattedDate, getTimeFromDate } from '../utils/dates'

const parseDate = (item) => {
  if (item.updatedAt) return new Date(item.updatedAt)

  const dateString = item.createdAt || item.date
  if (dateString) {
    const [datePart, timePart] = dateString.split(', ')
    const [day, month, year] = datePart.split('-')
    return new Date(`${year}-${month}-${day}T${timePart}`)
  }

  return new Date(0)
}

export const getMergedStatusLogs = (statusLogsRight, statusLogsLeft, isBothSides) => {
  const statusLogsLeftWithFlag = statusLogsLeft.map(item => ({ ...item, isLeft: true }))
  const statusLogsRightWithFlag = statusLogsRight.map(item => ({ ...item, isLeft: false }))

  const mergedLogs = isBothSides
    ? [...statusLogsRightWithFlag, ...statusLogsLeftWithFlag]
    : [...statusLogsRightWithFlag]

  return mergedLogs.sort((a, b) => parseDate(a) - parseDate(b))
}

export const getStatusText = (status, isBothSides) => {
  if (status.isLeft) return PACKAGE_STATUS_TRANSLATE[status.packageStatus]

  if (isBothSides) return status.statusDescription

  return status.status
}

export const getStatusDate = (status, isBothSides) => {
  if (status.isLeft) return shortDate(status.date)

  if (isBothSides) return shortDate(status.createdAt)

  return getFormattedDate(status.updatedAt)
}

export const getStatusTime = (status, isBothSides) => {
  if (status.isLeft) return getTimeFromFormattedDate(status.date)

  if (isBothSides) return getTimeFromFormattedDate(status.createdAt)

  return getTimeFromDate(status.updatedAt)
}
