import { ASSETS_URL } from './icons'

export const TICKETS_STATUS = [
  {
    value: 1,
    name: 'Creado'
  },
  {
    value: 2,
    name: 'En revisión'
  },
  {
    value: 3,
    name: 'Gestionado'
  },
  {
    value: 4,
    name: 'Resuelto'
  },
  {
    value: 5,
    name: 'Cerrado'
  }
]

export const TICKET_STATUS_MANAGEMENT = [
  {
    value: 1,
    name: 'Creado'
  },
  {
    value: 2,
    name: 'En revisión'
  },
  {
    value: 3,
    name: 'Gestionado'
  }
]

export const TICKET_STATUS_CLOSED_MANAGEMENT = [
  {
    value: 4,
    name: 'Resuelto'
  },
  {
    value: 5,
    name: 'Cerrado'
  }
]

export const TICKET_STATES_INDEX = {
  Creado: 1,
  'En revisión': 2,
  Gestionado: 3,
  Resuelto: 4,
  Cerrado: 5
}

export const TICKET_STATUSES_REVIEW_OPTIONS = {
  'En revisión': 'En revisión',
  Gestionado: 'Gestionado'
}

export const TICKET_STATUSES_TO_CHANGE = {
  'En revisión': 'En revisión',
  Gestionado: 'Gestionado',
  Resuelto: 'Resuelto'
}

export const STATUS_COLOR = {
  Creado: 'text-[#9747FF] bg-[#F7F0FF]',
  'En revisión': 'text-[#F0A155] bg-[#FFF2DF66]',
  Gestionado: 'text-[#0E9AFF] bg-[#0E9AFF14]',
  Resuelto: 'text-[#4EB03E] bg-[#34C24014]',
  Cerrado: 'text-[#E04BD1] bg-[#E04BD11A]'
}

export const CIRCLE_STATUS_COLOR = {
  Creado: 'bg-[#9747FF] border-[#9747FF]',
  'En revisión': 'bg-[#F0A155] border-[#F0A155]',
  Gestionado: 'bg-[#0E9AFF] border-[#0E9AFF]',
  Resuelto: 'bg-[#4EB03E] border-[#4EB03E]',
  Cerrado: 'bg-[#E04BD1] border-[#E04BD1]'
}

export const STATUS_ICONS = {
  Creado: `${ASSETS_URL}/tickets/created.png`,
  'En revisión': `${ASSETS_URL}/tickets/inReview.svg`,
  Gestionado: `${ASSETS_URL}/tickets/managed.png`,
  Resuelto: `${ASSETS_URL}/tickets/solved.png`,
  Cerrado: `${ASSETS_URL}/general/lock.svg`
}

export const TYPE_OPTIONS = {
  'Pedido / Pedido con demora': 'Pedido con demora',
  'Pedido / Pedido perdido': 'Pedido perdido',
  'Pedido / Dirección incorrecta': 'Dirección incorrecta',
  'Pedido / Devolución a remitente': 'Devolución a remitente',
  'Pedido / Cambiar destino': 'Cambiar destino',
  'Pedido / Problemas con retiro': 'Problemas con retiro',
  'Pedido / Agendar Retiro': 'Agendar Retiro',
  'Pedido / Desconocimiento entrega': 'Desconocimiento entrega',
  'Indemnizaciones / Pieza faltante': 'Pieza faltante',
  'Indemnizaciones / Entrega con daño': 'Entrega con daño',
  'Otros / Falla en plataforma': 'Falla en plataforma',
  'Otros / Otras consultas': 'Otras consultas',
  'Otros / Problema en pago': 'Problema en pago'
}

export const TICKET_OPTIONS_CLIENT = {
  'Pedido / Pedido con demora': 'Pedido con demora',
  'Pedido / Devolución a remitente': 'Devolución a remitente',
  'Pedido / Cambiar destino': 'Cambiar destino',
  'Indemnizaciones / Entrega con daño': 'Entrega con daño',
  'Otros / Falla en plataforma': 'Falla en plataforma',
  'Otros / Otras consultas': 'Otras consultas',
  'Otros / Problema en pago': 'Problema en pago'
}

export const OTHER_TYPE_OPTIONS = {
  'Otros / Falla en plataforma': 'Falla en plataforma',
  'Otros / Otras consultas': 'Otras consultas',
  'Otros / Problema en pago': 'Problema en pago'
}

export const PRIORITY_OPTIONS = {
  'HIGH': 'Alta',
  'MEDIUM': 'Media',
  'LOW': 'Baja'
}

export const MAX_DAYS_DIFF = 4

export const COURIER_ROUTES = {
  chilexpress: 'https://centrodeayuda.chilexpress.cl/seguimiento/',
  blueexpress: 'https://www.blue.cl/seguimiento/?n_seguimiento=',
  shippify: 'https://api.shippify.co/track/',
  globaltracking: 'https://globaltracking.cl/seguimiento/',
  recibelo: 'https://www.recibelo.cl/seguimiento/',
  urbano: 'https://portal.urbanoexpress.cl/rastrear-shipper/',
  fedex: 'https://clsclweb.tntchile.cl/txapgw/tracking.asp?boleto=',
  pickit :'https://tracking.pickit.cl/'
}

export const VIEW_BAR = [
  { name: 'tickets', label: 'Gestión de Tickets' },
  { name: 'finalizados', label: 'Tickets Finalizados' },
  { name: 'dashboard', label: 'Dashboard' }
]

export const TICKET_STATUSES = {
  OPEN: ['En revisión', 'Gestionado'],
  PENDING: ['Creado'],
  CLOSED: ['Resuelto', 'Cerrado'],
  CLOSED_AUTOMATICALLY: ['Cerrado'],
  ALL: ['Creado', 'En revisión', 'Gestionado', 'Resuelto', 'Cerrado'],
  ALL_WITHOUT_CLOSED: ['Creado', 'En revisión', 'Gestionado']
}

export const PRIORITY_COLOR = {
  HIGH: '#ff0000',
  MEDIUM: '#ff8000',
  LOW: '#1cbf13'
}
