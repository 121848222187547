import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { duplicateOrderIcon, greenCheck, refundIcon } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'
import { STATUS_COLOR, TYPE_OPTIONS } from '../../../constants/tickets'
import { getFormattedDate } from '../../../utils/dates'
import DuplicateOrder from '../../Sale/DuplicateOrder'
import Modal from '../../shared/Modal/Modal'

import ChangeResolvedStatus from './ChangeResolvedStatus'
import { SaleSearch } from './TicketsLinks'

const Header = ({ ticket, onTicketUpdated }) => {
  const [openChangeStatus, setOpenChangeStatus] = useState(false)
  const [openActions, setOpenActions] = useState(false)
  const [openOrderDuplication, setOpenOrderDuplication] = useState(false)

  const actionsRef = useRef(null)

  const { push } = useHistory()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setOpenActions(false)
      }
    }

    if (openActions) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openActions])

  const handleRefund = () => {
    push(`${ROUTES.CREATE_REFUND.split(':')[0]}${ticket.package.packageId}`)
  }

  const handleDuplicateOrder = () => {
    setOpenOrderDuplication(true)
  }

  return (
    <div className="flex flex-wrap justify-between rounded-lg bg-white p-4">
      <div>
        <div
          className={`mb-1 py-1 px-4 text-sm font-medium 
          ${STATUS_COLOR[ticket.status]} w-fit rounded`}
        >
          {ticket.status}
        </div>
        <div className="font-medium">Solicitud #{ticket.id}</div>
      </div>
      <div className="flex items-center gap-4 text-sm">
        <div>
          <div className="font-medium">Holding</div>
          <div className="text-medium-dark-grey">
            {ticket.holdingName}
          </div>
        </div>
        {ticket.package && Object.keys(ticket.package).length > 0 && (
          <>
            <div>
              <div className="font-medium">O. de venta</div>
              <div className="text-medium-dark-grey">
                <SaleSearch searchValue={ticket.package.orderId}/>
              </div>
            </div>
            <div>
              <div className="font-medium">Motivo</div>
              <div className="text-medium-dark-grey">
                {TYPE_OPTIONS[ticket.type]}
              </div>
            </div>
          </>
        )}
        <div>
          <div className="font-medium">Fecha de creación</div>
          <div className="text-medium-dark-grey">{getFormattedDate(ticket.createdAt)}</div>
        </div>
        {ticket.package && Object.keys(ticket.package).length > 0 && (
          <>
            <div>
              <div className="font-medium">Nombre</div>
              <div className="text-medium-dark-grey">{ticket.package.customerName}</div>
            </div>
            <div>
              <div className="font-medium">Email</div>
              <div className="text-medium-dark-grey">{ticket.package.customerEmail}</div>
            </div>
          </>
        )}
      </div>
      <div className="my-auto flex gap-2">
        <div className="relative w-16" ref={actionsRef}>
          {openActions && (
            <div className="absolute left-[-220%] top-[110%] z-10 border-2 bg-white p-2 drop-shadow-lg">
              <button
                type="button"
                onClick={() => handleRefund()}
                className="block w-full px-2 py-1 text-left text-sm hover:bg-normal-grey"
              >
                <div className="flex items-center gap-2">
                  <img src={refundIcon} alt="enviar" className="h-6 py-1" />
                  Crear indemnización
                </div>
              </button>
              <button
                type="button"
                onClick={() => handleDuplicateOrder()}
                className="block w-full px-2 py-1 text-left text-sm hover:bg-normal-grey"
              >
                <div className="flex items-center gap-2">
                  <img src={duplicateOrderIcon} alt="enviar" className="h-6 py-1" />
                  Duplicar orden
                </div>
              </button>
              <button
                type="button"
                onClick={() => setOpenChangeStatus(true)}
                className="block w-full px-2 py-1 text-left text-sm hover:bg-normal-grey"
              >
                <div className="flex items-center gap-2">
                  <img src={greenCheck} alt="enviar" className="h-6 py-1" />
                  Marcar como resuelto
                </div>
              </button>
            </div>
          )}
          <button
            type="button"
            onClick={() => setOpenActions(!openActions)}
            className="z-0 flex h-9 w-fit rotate-90 rounded border border-medium-gray px-3 text-base font-bold"
          >
            ...
          </button>
        </div>
      </div>
      <Modal
        title="Marcar como resuelto"
        show={openChangeStatus}
        handleClose={() => setOpenChangeStatus(false)}
      >
        <ChangeResolvedStatus
          ticketId={ticket.id}
          onTicketUpdated={onTicketUpdated}
          setOpenChangeStatus={setOpenChangeStatus}
        />
      </Modal>
      <Modal
        title="Duplicar orden"
        show={openOrderDuplication}
        handleClose={() => setOpenOrderDuplication(false)}
      >
        <DuplicateOrder
          order={ticket.package}
        />
      </Modal>
    </div>
  )
}

export default Header
