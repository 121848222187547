import React, { useState } from 'react'
import moment from 'moment'

import { exportIcon } from '../../constants/icons'
import { TICKETS_STATUS, TYPE_OPTIONS } from '../../constants/tickets'
import { exportTickets } from '../../helpers/requests/tickets'
import CheckColumn from '../Sales/CheckFilters/CheckColumn'
import Button from '../shared/Button'
import Loader from '../shared/Loader'
import Modal from '../shared/Modal/Modal'
import ResponseModal from '../shared/Modal/ResponseModal'
import ToolTip from '../shared/ToolTip'

const ExportTickets = ({ tickets }) => {
  const [isOpen, setIsOpen] = useState(false)
  const status = TICKETS_STATUS.map((state) => state.name)
  const ticketReasons = Object.values(TYPE_OPTIONS)
  const holdings = tickets.reduce((acc, ticket) => {
    acc[ticket.holdingName] = ticket.holdingId
    return acc
  }, {})

  const couriers = [
    ...new Set(tickets.map((ticket) => ticket.courier))
  ].filter((courier) => courier)

  const priorities = [...new Set(tickets.map((ticket) => ticket.priority))]
  const reviewers = tickets.reduce((acc, ticket) => {
    if (ticket.reviewer?.email) acc[ticket.reviewer.email] = ticket.reviewer.id
    return acc
  }, {})

  const [checkStatus, setCheckStatus] = useState(new Array(status.length).fill(false))
  const [checkReasons, setCheckReasons] = useState(new Array(ticketReasons.length).fill(false))
  const [checkHoldings, setCheckHoldings] = useState(
    new Array(Object.keys(holdings).length).fill(false)
  )
  const [checkCouriers, setCheckCouriers] = useState(new Array(couriers.length).fill(false))
  const [checkPriorities, setCheckPriorities] = useState(new Array(priorities.length).fill(false))
  const [checkReviewers, setCheckReviewers] = useState(
    new Array(Object.keys(reviewers).length).fill(false)
  )

  const today = new Date()
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState(today)

  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [email, setEmail] = useState('')
  const [step, setStep] = useState(0)
  const [ticketExported, setTicketExported] = useState(0)

  const handleChecks = {
    status: [checkStatus, setCheckStatus],
    reasons: [checkReasons, setCheckReasons],
    holdings: [checkHoldings, setCheckHoldings],
    couriers: [checkCouriers, setCheckCouriers],
    priorities: [checkPriorities, setCheckPriorities],
    reviewers: [checkReviewers, setCheckReviewers]
  }

  const handleChangeInitialDate = (event) => {
    setInitialDate(event.target.value)
  }

  const handleChangeFinalDate = (event) => {
    const lastDate = event.target.value

    if (lastDate === '') {
      setFinalDate(today)
    } else {
      setFinalDate(lastDate)
    }
  }

  const activeChecks = (checksState) =>
    !checksState.every((value) => value === false)

  const handleChecked = (position, checkState, setCheckState) => {
    const updatedCheckedState = checkState.map((check, index) =>
      index === position ? !check : check
    )

    setCheckState(updatedCheckedState)
  }

  const handleDate = () => {
    const body = { email }

    if (initialDate !== '') body.startDate = moment(initialDate).format('DD-MM-YYYY')

    if (moment(finalDate).format('DD-MM-YYYY') !== moment(today).format('DD-MM-YYYY')) {
      body.finishDate = moment(finalDate).format('DD-MM-YYYY')
    }

    return body
  }

  const getFilters = {
    holdingsIds: { check: checkHoldings, data: Object.keys(holdings), dataObject: holdings },
    courierNames: { check: checkCouriers, data: couriers },
    ticketPriority: { check: checkPriorities, data: priorities },
    ticketReviewer: { check: checkReviewers, data: Object.keys(reviewers), dataObject: reviewers },
    ticketStatus: { check: checkStatus, data: status },
    ticketType: { check: checkReasons, data: ticketReasons }
  }

  const makeBody = () => {
    const body = handleDate()

    Object.keys(getFilters).forEach((filter) => {
      const { check, data, dataObject } = getFilters[filter]
      if (activeChecks(check)) {
        const filtered = data.filter((_id, index) => check[index])
        body[filter] = filtered.flat()
        if (dataObject) body[filter] = body[filter].map((id) => dataObject[id])
      }

    })

    return body
  }

  const handleSubmit = async () => {
    setIsLoadingExport(true)
    const body = makeBody()
    try {
      const ticketCount = await exportTickets(body)
      setTicketExported(ticketCount)
    } catch (errorSubmit) {
      setErrorMessage(
        errorSubmit.response?.data?.message || JSON.stringify(errorSubmit.response || errorSubmit)
      )
    } finally {
      setStep(0)
      setResponseModalOpen(true)
      setIsOpen(false)
      setIsLoadingExport(false)
    }
  }

  return (
    <>
      <ToolTip
        backgroundColor="bg-ultra-dark-grey"
        textColor="text-white"
        hoverElement={
          <button
            className="h-9 min-w-[1.75rem] rounded-lg border p-0.5 hover:bg-semi-light-grey"
            onClick={() => setIsOpen(true)}
            type="button"
          >
            <img src={exportIcon} alt="exportar" className="m-1.5 w-3" />
          </button>
        }
        right
      >
        Exportar
      </ToolTip>
      <Modal show={isOpen} handleClose={() => setIsOpen(false)}>
        <div className="text-lg font-medium text-ultra-dark-grey">Exportar solicitudes</div>
        {step === 0 ? (
          <div className="flex flex-col items-center text-sm">
            <div className="flex max-h-[50vh] w-fit flex-wrap overflow-auto lg:max-w-none lg:flex-nowrap">
              <CheckColumn
                name="Holdings:"
                checkList={Object.keys(holdings)}
                handleChange={(position) => handleChecked(position, ...handleChecks.holdings)}
                checkState={checkHoldings}
              />
              <CheckColumn
                name="Estados:"
                checkList={status}
                handleChange={(position) => handleChecked(position, ...handleChecks.status)}
                checkState={checkStatus}
              />
              <CheckColumn
                name="Tipo de Ticket:"
                checkList={ticketReasons}
                handleChange={(position) => handleChecked(position, ...handleChecks.reasons)}
                checkState={checkReasons}
              />
              <CheckColumn
                name="Courier:"
                checkList={couriers}
                handleChange={(position) => handleChecked(position, ...handleChecks.couriers)}
                checkState={checkCouriers}
              />
              <CheckColumn
                name="Prioridad:"
                checkList={priorities}
                handleChange={(position) => handleChecked(position, ...handleChecks.priorities)}
                checkState={checkPriorities}
              />
              <CheckColumn
                name="Asignado a:"
                checkList={Object.keys(reviewers)}
                handleChange={(position) => handleChecked(position, ...handleChecks.reviewers)}
                checkState={checkReviewers}
              />
            </div>
            <div className="my-4 flex items-center gap-2 pb-3">
              <span className="font-medium text-ultra-dark-grey">Fecha:</span>
              <input
                className="rounded border py-1 text-xs text-medium-dark-grey"
                type="date"
                name="initialDate"
                placeholder="Desde"
                max={finalDate}
                value={initialDate}
                onChange={handleChangeInitialDate}
              />
              <input
                className="rounded border py-1 text-xs text-medium-dark-grey"
                type="date"
                name="finalDate"
                placeholder="Hasta"
                max={today}
                min={initialDate}
                value={finalDate}
                onChange={handleChangeFinalDate}
              />
            </div>
            {isLoadingExport ? (
              <Loader />
            ) : (
              <Button color="bg-pinflag" onClick={() => setStep(1)}>
                Continuar
              </Button>
            )}
          </div>
        ) : (
          <>
            <div className="my-8 grid grid-cols-3">
              <div>Exportar a</div>
              <input
                onChange={(event) => setEmail(event.target.value)}
                className="col-span-2 rounded-sm border border-medium-gray py-1 px-2 text-sm lg:w-60"
                type="email"
                placeholder="Correo electrónico"
                value={email}
              />
            </div>
            <div className="my-2 flex justify-center gap-2">
              {isLoadingExport ? (
                <Loader />
              ) : (
                <>
                  <Button color="bg-dark-grey" onClick={() => setStep(0)}>
                    Volver
                  </Button>
                  <Button color="bg-pinflag" onClick={handleSubmit}>
                    Exportar
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </Modal>
      <ResponseModal
        handleClose={() => setResponseModalOpen(false)}
        show={responseModalOpen}
        successMessage={
          ticketExported < 3000
            ? `Se han exportado ${ticketExported} solicitudes.`
            : `Solicitudes exportadas a ${email}.`
        }
        error={errorMessage}
      />
    </>
  )
}

export default ExportTickets
