import { DONUT_COLORS } from '../../constants/stats'
import { TYPE_OPTIONS, TICKET_STATUSES } from '../../constants/tickets'

export const parseLastWeekTickets = (lastWeekTickets) =>
  lastWeekTickets.map((dayTicket) => ({
    name: dayTicket.weekDay,
    value: dayTicket.ticketsCount,
    date: dayTicket.date.slice(0, 5)
  }))

export const parseTicketsByHolding = (ticketsByHolding) =>
  ticketsByHolding.map(ticketHolding => ({
    name: ticketHolding.holdingName,
    value: ticketHolding.ticketsCount,
    percentage: ticketHolding.percentageOfTotal === null ? '0%' : `${ticketHolding.percentageOfTotal}%`
  }))


export const parseTicketsByType = (ticketsByType) =>
  ticketsByType.map(ticketType => ({
    name: ticketType.ticketType.split('/')[1],
    value: ticketType.ticketsCount,
    percentage: ticketType.percentageOfTotal === null ? '0%' : `${ticketType.percentageOfTotal}%`
  }))

const getStartDate = (queryString) => {
  const params = new URLSearchParams(queryString)
  const startDateStr = params.get('startDate')
  return new Date(startDateStr.split('-').reverse().join('-'))
}

const getFinishDate = (queryString) => {
  const params = new URLSearchParams(queryString)
  const finishDateStr = params.get('finishDate')
  return finishDateStr
  ? new Date(finishDateStr.split('-').reverse().join('-'))
  : new Date()
}

const parseQueryFilters = (queryString) => ({
  startDate: getStartDate(queryString),
  endDate: getFinishDate(queryString),
  holdingIds: new Set(
    new URLSearchParams(queryString).get('holdingId')?.split(',').map(id => parseInt(id, 10)) || []
  )
})

export const filterTickets = (tickets, queryString) => {
  const { startDate, endDate, holdingIds } = parseQueryFilters(queryString)

  return tickets.filter(ticket => {
    const createdAt = new Date(ticket.createdAt)
    const formatDate = (date) => date.toISOString().split('T')[0]

    const createdDate = formatDate(createdAt)
    const startDateFormatted = startDate ? formatDate(startDate) : null
    const endDateFormatted = endDate ? formatDate(endDate) : null

    const withinDateRange = (!startDateFormatted || createdDate >= startDateFormatted) &&
      (!endDateFormatted || createdDate <= endDateFormatted)

    const withinHolding = holdingIds.size === 0 || holdingIds.has(ticket.holdingId)

    return withinDateRange && withinHolding
  })
}

export const countOpenTickets = (tickets) => tickets.reduce(
    (count, ticket) => TICKET_STATUSES.OPEN.includes(ticket.status) ? count + 1 : count,
    0
  )

export const countPendingTickets = (tickets) =>
  tickets.filter(ticket =>
    ticket.status === TICKET_STATUSES.PENDING[0] || (
      (ticket.lastMessageSender !== null && ticket.lastMessageSender === 'ecommerce')
      && (ticket.status !== TICKET_STATUSES.CLOSED_AUTOMATICALLY[0])
    )
  ).length

export const averageResponseTime = (tickets) => {
  let totalTime = 0
  let count = 0

  tickets.forEach(ticket => {
    if (ticket.firstPinflagMessageDate !== null) {
      const createdAt = new Date(ticket.createdAt)
      const firstMessageAt = new Date(ticket.firstPinflagMessageDate)
      totalTime += (firstMessageAt - createdAt)
      count += 1
    }
  })

  return count > 0 ? (totalTime / count / 1000 / 60 / 60).toFixed(2) : '0'
}

export const averageCloseTicket = (tickets) => {
  let totalTime = 0
  let count = 0

  tickets.forEach(ticket => {
    if (TICKET_STATUSES.CLOSED.includes(ticket.status)) {
      const createdAt = new Date(ticket.createdAt)
      const closedAt = new Date(ticket.closedAt)
      totalTime += (closedAt - createdAt)
      count += 1
    }
  })

  return count > 0 ? (totalTime / count / 1000 / 60 / 60).toFixed(2) : '0'
}

export const getMonthRangeStats = (queryString) => {
  const startDate = getStartDate(queryString)
  const finishDate = getFinishDate(queryString)

  const monthNames = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
  ]

  const startMonth = monthNames[startDate.getMonth()]
  const finishMonth = monthNames[finishDate.getMonth()]

  return startMonth === finishMonth ? startMonth : `${startMonth}/${finishMonth}`
}

export const getPreviousPeriod = (queryString) => {
  const params = new URLSearchParams(queryString)

  const startDate = getStartDate(queryString)
  const finishDate = getFinishDate(queryString)

  const diffTime = finishDate - startDate
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const prevFinishDate = new Date(startDate)
  prevFinishDate.setDate(startDate.getDate() - 1)

  const prevStartDate = new Date(startDate)
  prevStartDate.setDate(startDate.getDate() - diffDays)

  const formatDate = (date) => date.toISOString().split('T')[0].split('-').reverse().join('-')

  params.set('startDate', formatDate(prevStartDate))
  params.set('finishDate', formatDate(prevFinishDate))

  return `?${params.toString()}`
}

export const calculatePercentageDifference = (previousValue, currentValue) => {
  const prev = parseFloat(previousValue)
  const curr = parseFloat(currentValue)

  if (prev === curr) return '0'

  if (prev === 0) return curr > 0 ? '∞' : '0'

  const difference = ((curr - prev) / prev) * 100
  return `${difference.toFixed(2)}`
}

export const getStatsValues = (actualTickets, pastTickets, getStatsFunction) => {
  const actual = getStatsFunction(actualTickets)
  const past = getStatsFunction(pastTickets)
  const difference = calculatePercentageDifference(past, actual)
  return { actual, past, difference }
}

export const processTicketTypes = (current, previous) => {
  const currentTypes = current.reduce((acc, ticket) => {
    acc[ticket.type] = (acc[ticket.type] || 0) + 1
    return acc
  }, {})

  const previousTypes = previous.reduce((acc, ticket) => {
    acc[ticket.type] = (acc[ticket.type] || 0) + 1
    return acc
  }, {})

  const total = current.length
  return Object.entries(currentTypes).map(([type, count], index) => ({
    name: TYPE_OPTIONS[type],
    fill: DONUT_COLORS[index % DONUT_COLORS.length],
    value: Number(((count / total) * 100).toFixed(2)),
    percentage: parseFloat(((count / total) * 100).toFixed(2)),
    difference: previousTypes[type]
      ? `${(((count - previousTypes[type]) / previousTypes[type]) * 100).toFixed(2)  }%`
      : '+0.00'
  }))
}

export const processClientStats = (tickets) => {
  const clientCounts = tickets.reduce((acc, ticket) => {
    const holdingName = ticket.holdingName || 'Sin holding'
    acc[holdingName] = (acc[holdingName] || 0) + 1
    return acc
  }, {})

  return Object.entries(clientCounts)
    .map(([name, count]) => ({ name, count }))
    .sort((a, b) => b.count - a.count)
}
