import React, { useState } from 'react'

import Modal from '../../shared/Modal/Modal'
import Button from '../../shared/Button'
import Loader from '../../shared/Loader'
import { addCircle } from '../../../constants/icons'
import { createTicketNote } from '../../../helpers/requests/tickets'
import { getFormattedDate } from '../../../utils/dates'

const Notes = ({ ticket, onNoteCreated }) => {
  const { notes } = ticket
  const [openNoteForm, setOpenNoteForm] = useState(false)
  const [note, setNote] = useState({
    title: '',
    message: ''
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmitNote = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await createTicketNote(ticket.id, note)
      onNoteCreated()
      setNote({ title: '', message: '' })
    } catch (err) {
      if (err.response) setErrorMessage(err.response.data?.errors[0]?.message)
    }
    setIsLoading(false)
  }

  const createNoteForm = () => (
    <form onSubmit={handleSubmitNote} className="flex flex-col gap-4">
      <div className="mt-8 flex w-80 flex-col gap-2">
        <label className="mb-2 block text-sm font-medium">Título:</label>
        <input
          type="text"
          className="w-full rounded-md bg-light-grey p-2"
          value={note.title}
          onChange={(e) => setNote({ ...note, title: e.target.value })}
        />
      </div>
      <div>
        <label className="mb-2 block text-sm font-medium">Mensaje:</label>
        <textarea
          className="h-32 w-full rounded-md bg-light-grey p-2"
          value={note.message}
          onChange={(e) => setNote({ ...note, message: e.target.value })}
        />
      </div>

      {errorMessage && <div className="text-red">{errorMessage}</div>}

      <div className="mt-4 flex justify-end gap-2">
        <Button submit color="bg-pinflag-dark">
          {isLoading ? <Loader sizeClassname="h-4 w-4" borderColor="border-white" /> : 'Guardar'}
        </Button>
      </div>
    </form>
  )

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-8">
      <div className="text-lg font-medium">Notas</div>
      {notes.map((ticketNote) => (
        <div className="flex flex-col" key={ticketNote.id}>
          <div className="mt-4">
            <div className="font-medium">{ticketNote.title}</div>
            <div className="mt-2 text-medium-dark-grey">{ticketNote.message}</div>
            <div className="mt-2 text-medium-dark-grey">{ticketNote.author.name} - {getFormattedDate(ticketNote.createdAt)}</div>
          </div>
        </div>
      ))}
      <div className="flex w-full items-center justify-between rounded-lg border border-pinflag-200 bg-light-pinflag py-4 px-6">
        <span>Crear nueva Nota</span>
        <button type="button" onClick={() => setOpenNoteForm(!openNoteForm)}>
        <img src={addCircle} alt="enviar" className="h-7" />
        </button>
      </div>
      <Modal show={openNoteForm} handleClose={() => setOpenNoteForm(false)} title="Nueva Nota">
        {createNoteForm()}
      </Modal>
    </div>
  )
}

export default Notes
