import React, { useState, useContext } from 'react'

import { notificationContext } from '../../../contexts/NotificationContext'
import { readNotificationHelper } from '../../../helpers/notifications'
import useNotifications from '../../../hooks/useNotifications'
import ManageTicket from '../../../pages/Tickets/ManageTicket'

import TicketContainerWindowStyle from './TicketContainerWindow.Style'

const TicketContainerWindow = ({ ticketId, openTicket, setOpenTicket }) => {
  const [runAnimation, setRunAnimation] = useState(false)

  const { readNotificationsAndUpdate } = useNotifications()

  const { ticketsInfo } = useContext(notificationContext)

  const closeSidebar = () => {
    setRunAnimation(true)
    setTimeout(() => {
      setRunAnimation(false)
      setOpenTicket(false)
    }, 1000)
  }

  const clickOutside = async (e) => {
    if (e.currentTarget !== e.target) return
    closeSidebar()
    await readNotificationHelper(
      ticketsInfo,
      ticketId,
      readNotificationsAndUpdate
    )
  }

  return (
    <TicketContainerWindowStyle>
      <>
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={`sidebar-container ${!openTicket ? 'hidden' : ''} ${
            runAnimation ? 'fade-out' : ''
          }`}
          onClick={clickOutside}
          role="button"
          tabIndex="0"
        />
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={`sidebar-modal ${openTicket ? '' : 'hidden'} ${
            runAnimation ? 'slide-out' : ''
          } flex h-full flex-col overflow-hidden`}
        >
          <ManageTicket id={ticketId} isFromTickets />
        </div>
      </>
    </TicketContainerWindowStyle>
  )

}
export default TicketContainerWindow
