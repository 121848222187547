import React, { useState } from 'react'
import moment from 'moment'

import { filterIcon } from '../../../constants/icons'
import useClickOutside from '../../../hooks/useClickOutside'
import { datesHasChanged } from '../../../utils/filters'
import { capitalizeFirstLetter } from '../../../utils/strings'
import ToolTip from '../ToolTip'

import CheckColumn from './CheckColumn'

const Filters = ({
  filterOptions,
  selectedCouriers,
  setSelectedCouriers,
  today,
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
  handleSubmit,
  handleClean,
  isFiltered
}) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()
  const [openedFilter, setOpenedFilter] = useState(null)

  const handleInitialDate = (event) => {
    setInitialDate(event.target.value)
  }

  const handleFinalDate = (event) => {
    const lastDate = event.target.value
    if (lastDate === '') {
      setFinalDate(today)
    } else {
      setFinalDate(lastDate)
    }
  }

  const handleFilterCheck = (category, option) => {
    setSelectedCouriers((prev) => ({
      ...prev,
      [category]: prev[category]?.includes(option)
        ? prev[category].filter((item) => item !== option)
        : [...(prev[category] || []), option]
    }))
  }

  const filtersCheckQuantity =
    selectedCouriers.length + (datesHasChanged(initialDate, finalDate, today) ? 1 : 0)

  return (
    <div className="relative" ref={popUpRef}>
      <ToolTip
        backgroundColor="bg-ultra-dark-grey"
        textColor="text-white"
        hoverElement={
          <button
            className={`flex h-10 min-w-[2.5rem] items-center justify-center rounded-lg p-0.5 ${
              isFiltered ? 'bg-ultra-dark-grey hover:opacity-70' : 'border hover:bg-semi-light-grey'
            }`}
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              src={filterIcon}
              alt="filtrar"
              className={`my-auto mx-1.5 w-5 ${isFiltered ? 'invert' : ''}`}
            />
            {filtersCheckQuantity > 0 && isFiltered && (
              <div className="m-0.5 my-auto rounded-md bg-white px-1.5 text-xs">
                {filtersCheckQuantity}
              </div>
            )}
          </button>
        }
      >
        Filtrar
      </ToolTip>

      {isOpen && (
        <div
          className='absolute right-0 top-8 z-20 flex flex-col gap-2 rounded bg-white
         p-3 text-sm shadow'
        >
          <div className="flex max-h-[60vh] flex-col gap-2 overflow-auto">
            {Object.entries(filterOptions).map(([category, options], index) => (
              <CheckColumn
                key={category}
                isOpen={openedFilter === index}
                open={() => setOpenedFilter(openedFilter === index ? null : index)}
                name={capitalizeFirstLetter(category)}
                checkList={options}
                handleCheck={(option) => handleFilterCheck(category, option)}
                checkState={options.map((option) => selectedCouriers[category]?.includes(option))}
              />
            ))}
          </div>
          <hr className="border-normal-grey" />
          {(initialDate || finalDate) && (
            <>
              <div className="font-medium">Fecha</div>
              <div className="flex gap-1">
                <input
                  className="rounded border py-1 text-xs text-medium-dark-grey"
                  type="date"
                  value={initialDate}
                  max={finalDate}
                  onChange={handleInitialDate}
                />
                <input
                  className="rounded border py-1 text-xs text-medium-dark-grey"
                  type="date"
                  value={moment(finalDate).format('YYYY-MM-DD')}
                  max={today}
                  min={initialDate}
                  onChange={handleFinalDate}
                />
              </div>
            </>
          )}
          <button
            type="button"
            className="w-full rounded bg-pinflag py-2 text-white"
            onClick={handleSubmit}
          >
            Filtrar
          </button>
          {isFiltered && (
            <button
              type="button"
              className="w-full rounded border border-ultra-dark-grey py-2 text-ultra-dark-grey"
              onClick={() => {
                handleClean()
                setIsOpen(false)
              }}
            >
              Limpiar
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default Filters
