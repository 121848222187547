import React, { useState, useContext } from 'react'

import { TICKET_ICONS, attentionIcon } from '../../../constants/icons'
import { notificationContext } from '../../../contexts/NotificationContext'
import { readNotificationHelper } from '../../../helpers/notifications'
import { sendTicketMessage } from '../../../helpers/requests/tickets'
import useNotifications from '../../../hooks/useNotifications'
import { formatDate } from '../../../utils/dates'

const Chat = ({ chatHistory, ticketId, unreadMessages, setUnreadMessages, isInDetail,
  isClosedTicket }) => {
  const [history, setHistory] = useState(
    [...chatHistory].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  )
  const [messageTosend, setMessageToSend] = useState('')
  const [localUnreadMessages, setLocalUnreadMessages] = useState(unreadMessages)
  const [showTooltip, setShowTooltip] = useState(false)

  const { readNotificationsAndUpdate } = useNotifications()
  const { ticketsInfo } = useContext(notificationContext)

  const unreadIndices = [...Array(localUnreadMessages).keys()].map(
    (i) => history.length - localUnreadMessages + i
  )

  const sendMessage = async () =>  {
    if (messageTosend.trim() === '') return
    const responseData = await sendTicketMessage(ticketId, messageTosend)
    setHistory(history.concat(responseData))
    await readNotificationHelper(
      ticketsInfo,
      ticketId,
      readNotificationsAndUpdate
    )
    setUnreadMessages(0)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    sendMessage()
    setMessageToSend('')
    setLocalUnreadMessages(0)
  }

  return (
    <div className="flex h-full flex-col">
      <div className={`flex flex-col rounded-lg bg-light-grey p-2 ${
        isInDetail
          ? 'mt-4 max-h-[75vh] min-h-[30vh] max-w-full gap-2'
          : 'h-full flex-auto'
        }`}>
        <div className="flex-1 overflow-y-auto">
          <div className="flex min-h-full flex-col justify-end">
            <div className="flex flex-col">
            {history.length === 0 && (
              <div className="my-4 text-center text-medium-gray">Aún no hay mensajes</div>
            )}
            {history.map((message, index) => (
              <div
                key={message.createdAt}
                className={`${
                  message.sender === 'ecommerce' ? 'mr-auto ml-2' : 'ml-auto mr-2 text-end'
                } mt-2 w-fit max-w-[65%]`}
              >
                <div
                  className={`w-fit overflow-hidden break-words rounded-b-2xl py-2
                  px-4 text-sm ${
                    message.sender === 'ecommerce'
                      ? 'ml-0 mr-auto rounded-tr-2xl bg-white'
                      : 'mr-0 ml-auto rounded-tl-2xl bg-pinflag text-white'
                  }`}
                >
                  {message.message}
                </div>
                <div className="flex items-center gap-2 text-xs text-dark-grey">
                  {unreadIndices.includes(index) && (
                    <div className="h-2 w-2 rounded-full bg-red" />
                  )}
                  {formatDate(message.createdAt)}
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
        <form className="relative m-2" onSubmit={handleSubmit}>
        {isClosedTicket ? (
          <div className="relative">
            <textarea
              className="h-[42px] w-full resize-none overflow-hidden break-words rounded-2xl bg-white py-3 px-12 text-sm shadow-lg"
              placeholder="Chat no disponible"
              disabled
              style={{
                lineHeight: '20px'
              }}
            />
            <div className="absolute left-3 top-1/2 -translate-y-1/2">
              <button
                type="button"
                className="rounded-full p-1 hover:bg-gray-100"
                onClick={() => setShowTooltip(!showTooltip)}
                aria-label="Información sobre chat no disponible"
              >
                <img
                  src={attentionIcon}
                  alt="Info"
                  className="h-5 w-5"
                />
              </button>
              {showTooltip && (
                <div className="absolute bottom-full left-0 z-50 mb-2 w-64 rounded-lg border border-pinflag bg-white p-3 text-xs text-medium-dark-grey shadow-lg">
                  <div className="flex items-start gap-2">
                    <img
                      src={attentionIcon}
                      alt="Atención"
                      className="mt-0.5 h-4 w-4"
                    />
                    <span>
                      Este chat no se encuentra disponible. Para poder seguir ocupando
                      {' '}esta funcionalidad, el cliente debe re-abrir la solicitud.
                    </span>
                  </div>
                  <div className="absolute -bottom-2 left-4 h-4 w-4 rotate-45 border-b border-r border-pinflag bg-white" />
                </div>
              )}
            </div>
            <div className="absolute top-1/2 right-3 -translate-y-1/2">
              <img
                src={TICKET_ICONS.send}
                alt="enviar"
                className="h-5 w-5 opacity-50"
              />
            </div>
          </div>
        ) : (
          <div className="relative">
            <textarea
              className="h-fit w-full resize-none break-words rounded-2xl bg-white py-3 pl-4 pr-12 text-sm shadow-lg"
              placeholder="Escribe un mensaje"
              value={messageTosend}
              onChange={(event) => setMessageToSend(event.target.value)}
              maxLength="500"
            />
            <button
              type="submit"
              className="absolute top-1/2 right-3 -translate-y-1/2"
            >
              <img
                src={TICKET_ICONS.send}
                alt="enviar"
                className="h-5 w-5"
              />
            </button>
          </div>
        )}
      </form>
      </div>
    </div>
  )
}

export default Chat
