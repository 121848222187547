import React from 'react'

import { greenCheck, TICKET_ICONS } from '../../../constants/icons'
import { TICKET_STATUSES, CIRCLE_STATUS_COLOR } from '../../../constants/tickets'
import { getMergedStatusLogs, getStatusDate, getStatusTime, getStatusText } from '../../../helpers/ticketTimeLine'

const TimeLine = ({ statusLogsRight, statusLogsLeft, isBothSides }) => {
  const containerHeight = `${(statusLogsRight.length * 80) +
    (isBothSides ? statusLogsLeft.length * 80 : 0)}px`

  const getStatusIcon = (status) => {
    if (!TICKET_STATUSES.ALL.includes(status)) {
      return (
        <span className='z-10 block h-4 w-4 rounded-full border-2 border-green bg-green' >
          {' '}
        </span>
      )
    }
    if (status === 'Cerrado') return <img src={TICKET_ICONS.closeTicket} alt="Closed" className="w-5"/>
    if (status === 'Resuelto') return <img src={greenCheck} alt="Resolved" className="w-5"/>
    return (
      <span className={`z-10 block h-4 w-4 rounded-full border-2 ${CIRCLE_STATUS_COLOR[status]}`}>
        {' '}
      </span>
    )
  }

  const mergedStatusLogs = getMergedStatusLogs(statusLogsRight, statusLogsLeft, isBothSides)

  return (
    <div className={`relative flex w-full ${isBothSides ? 'justify-center' : ''}`} style={{ height: containerHeight }}>
      <div className="relative top-[-2.5rem] ml-10 flex h-20 w-max">
      {mergedStatusLogs && (
        <div className="relative flex h-full flex-col">
          {mergedStatusLogs.map((status, index) => (
            <div key={status.id} className="relative flex min-h-full flex-col px-1.5 text-left">
              {index !== 0 ? (
                <span className="relative top-0 left-2 z-0 h-full w-0 border border-medium-dark-grey">
                  {' '}
                </span>
              ) : (
                <span className="relative top-0 left-2 z-0 h-full w-0"> </span>
              )}
              {index === mergedStatusLogs.length - 1 ? (
                getStatusIcon(status.status)
              ) : (
                <span className='z-10 block h-4 w-4 rounded-full border-4 border-medium-dark-grey bg-medium-dark-grey'>
                  {' '}
                </span>
              )}
              <div className={`absolute top-14 whitespace-nowrap ${status.isLeft ? 'right-10 text-right' : 'left-10 text-left'}`}>
                <span className="text-sm font-medium text-medium-dark-grey">{getStatusText(status, isBothSides)}</span>
                <div className="block text-xs">
                  <div>{getStatusDate(status, isBothSides)}</div>
                  <div>{getStatusTime(status, isBothSides)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
)}

export default TimeLine
