import React, { useState, useEffect, useCallback, useContext } from 'react'

import { TICKET_STATUSES, TICKET_STATUS_CLOSED_MANAGEMENT, TICKET_STATES_INDEX } from '../../../constants/tickets'
import { TicketsContext } from '../../../contexts/TicketsContext'
import { getTickets } from '../../../helpers/requests/tickets'
import useFetch from '../../../hooks/useFetch'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { sortArrayByKey } from '../../../utils/array'
import { isSubstring } from '../../../utils/strings'
import Pagination from '../../shared/Pagination'
import SearchBar from '../../shared/SearchBar'
import ActionsBar from '../../shared/Table/ActionsBar'
import TicketsSelection from '../SelectionBar/TicketsSelection'
import TicketsTable from '../TicketsTable'
import ExportTickets from '../ExportTickets'

const ClosedTickets = () => {
  const [tickets, setTickets] = useState([])
  const [searchBarInput, setSearchBarInput] = useLocalStorage('closedSearchBarInput', '')
  const [page, setPage] = useLocalStorage('closedPage', 1)
  const [pageSize, setPageSize] = useLocalStorage('closedPageSize', 10)
  const [searchResult, setSearchResult] = useState([])
  const [selectedTickets, setSelectedTickets] = useState([])
  const [selectedFilters, setSelectedFilters] = useState([])

  const { setAllFilteredTickets, setAllTickets } = useContext(TicketsContext)

  const setTicketsData = useCallback((data) => {
    setAllTickets(data)
    const flattenTickets = data.map((ticket) => {
      const item = { ...ticket, ...ticket.package }
      delete item.package
      return item
    })

    const closedTickets = flattenTickets.filter(
      ticket => TICKET_STATUSES.CLOSED.includes(ticket.status)
    )

    closedTickets.sort((ticketA, ticketB) => ticketB.id - ticketA.id)

    setTickets(closedTickets)
    setSearchResult(closedTickets)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoading, error } = useFetch(getTickets, setTicketsData)

  useEffect(() => {
    if (searchBarInput !== '') {
      const filteredTickets = tickets.filter(
        (ticket) =>
          isSubstring(ticket.id.toString(), searchBarInput) ||
          (ticket.pinflagId && isSubstring(ticket.pinflagId, searchBarInput)) ||
          (ticket.courier && isSubstring(ticket.courier, searchBarInput)) ||
          (ticket.orderId && isSubstring(ticket.orderId, searchBarInput)) ||
          (ticket.customerName && isSubstring(ticket.customerName, searchBarInput)) ||
          (ticket.holdingName && isSubstring(ticket.holdingName, searchBarInput)) ||
          (ticket.trackingNumber && isSubstring(ticket.trackingNumber, searchBarInput))
      )
      setSearchResult(filteredTickets)
    } else {
      setSearchResult(tickets)
    }
  }, [searchBarInput, tickets])

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
  }

  const handleSort = (sortValues, order) => {
    const sortedCurrentTickets = sortArrayByKey(searchResult, sortValues, order)
    setSearchResult(sortedCurrentTickets)

    const sortedTickets = sortArrayByKey(tickets, sortValues, order)
    setTickets(sortedTickets)
  }

  const stateFilteredTickets = searchResult.filter((ticket) =>
    selectedFilters.length > 0
      ? selectedFilters.includes(TICKET_STATES_INDEX[ticket.status])
      : true
  )

  useEffect(() => {
    setAllFilteredTickets((prev) => {
      const filteredIds = stateFilteredTickets.map(ticket => ticket.id)
      if (JSON.stringify(prev) !== JSON.stringify(filteredIds)) {
        return filteredIds
      }
      return prev
    })
  }, [stateFilteredTickets, setAllFilteredTickets])

  const currentTickets = stateFilteredTickets.slice(pageSize * (page - 1), pageSize * page)

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <div className='flex w-full justify-end px-8'>
        <div className="mx-2 w-1/6 self-center overflow-hidden md:block">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
      </div>
      <ActionsBar
        statusFilters={TICKET_STATUS_CLOSED_MANAGEMENT}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        rightChildren={
          <div className="flex gap-4">
            {tickets.length > 0 && <ExportTickets tickets={tickets} />}
          </div>
        }
        customFirstFilter={{
          name: 'Todos',
          isActive: true
        }}
      />
      <TicketsTable
        tickets={currentTickets}
        selectedTickets={selectedTickets}
        setSelectedTickets={setSelectedTickets}
        handleSort={handleSort}
        isLoading={isLoading}
        error={error}
        isClosedTickets
      />
      <div className="mx-10 flex h-24 place-content-end">
        {stateFilteredTickets.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={stateFilteredTickets.length}
          />
        )}
        {selectedTickets.length > 0 && (
          <div className="absolute bottom-28 left-10">
            <TicketsSelection
              selectedTickets={selectedTickets}
              handleCloseBar={() => {
                setSelectedTickets([])
              }}
              isClosedTickets
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ClosedTickets
