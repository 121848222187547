import apiClient from '../../axiosConfig/apiClient'
import {
  CHANGE_TICKET_STATUS,
  CLOSE_TICKET, SEND_MESSAGE_URL,
  TICKETS_URL, TICKETS_LOGS_URL,
  CREATE_TICKET_NOTE,
  EDIT_TICKET_REVIEW,
  EXPORT_TICKETS,
  CHANGE_TICKETS_STATUS,
  TICKET_BY_ID
} from '../../constants/endpointUrls/api'
import { downloadFileUrl } from '../../utils/files'

export const getTickets = async () => {
  const response = await apiClient.get(TICKETS_URL)

  return response.data
}

export const getTicket = async (id) => {
  const response = await apiClient.get(`${TICKETS_URL}/${id}`)

  return response.data
}

export const getTicketsLogs = async () => {
  const response = await apiClient.get(`${TICKETS_LOGS_URL}`)

  return response.data
}

export const changeTicketStatus = (ticketId, statusSelected, body) =>
  apiClient.put(CHANGE_TICKET_STATUS(ticketId, statusSelected), body)

export const closeTicket = (ticketId, body) =>
  apiClient.put(CLOSE_TICKET(ticketId), body)

export const sendTicketMessage = async (ticketId, message) => {
  const body = {
    message,
    sender: 'pinflag'
  }
  const response = await apiClient.post(SEND_MESSAGE_URL(ticketId), body)

  return response.data
}

export const addImageToTicket = async (ticketId, formData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const response = await apiClient.post(`${TICKETS_URL}/${ticketId}/image`, formData, config)

  return response.data
}

export const editTicketReview = async (ticketId, body) =>
  apiClient.put(EDIT_TICKET_REVIEW(ticketId), body)

export const createTicketNote = async (ticketId, body) =>
  apiClient.post(CREATE_TICKET_NOTE(ticketId), body)

export const exportTickets = async (body) => {
  const response = await apiClient.post(EXPORT_TICKETS, body)

  downloadFileUrl(response.data.url)

  return 3000
}

export const changeTicketsStatus = async (body, status) => {
  const response = await apiClient.put(CHANGE_TICKETS_STATUS(status), body)

  return response.data
}

export const getTicketByPinflagId = async (pinflagId) => {
  const response = await apiClient.get(TICKET_BY_ID(pinflagId))

  return response.data
}
