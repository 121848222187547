import React, { useState } from 'react'

import AllTickets from '../../components/Tickets/AllTickets/AllTickets'
import ClosedTickets from '../../components/Tickets/ClosedTickets/ClosedTickets'
import TicketDashboard from '../../components/Tickets/Dashboard/TicketDashboard'
import ViewBar from '../../components/Tickets/ViewBar'

const Tickets = () => {
  const [currentView, setCurrentView] = useState('tickets')
  const [shouldFetch, setShouldFetch] = useState(true)

  return (
    <div className="flex h-[100vh] flex-col overflow-y-scroll bg-light-grey">
      <div className="relative m-10">
        <div className="mb-8 text-left text-xl font-semibold">Solicitudes</div>
        <div className="mr-10 flex items-center justify-between">
          <ViewBar setCurrentView={setCurrentView} currentView={currentView} />
        </div>
        {currentView === 'tickets' && (
          <AllTickets />
        )}
        {currentView === 'finalizados' && (
          <ClosedTickets />
        )}
        {currentView === 'dashboard' && (
          <TicketDashboard setShouldFetch={setShouldFetch} shouldFetch={shouldFetch} />
        )}
      </div>
    </div>
  )
}

export default Tickets
