import React from 'react'

import { getFormattedDateWithoutTimezone } from '../../../utils/dates'
import FilterTag from '../../shared/FilterTag'

const FilterTags = ({
  checkedDate,
  initialDate,
  finalDate,
  selectedFilters,
  holdings,
  cleanTag,
  companies
}) => {
  const today = new Date().toLocaleDateString('es-CL')

  const datesText = () => {
    switch (true) {
      case checkedDate !== 0:
        return `Últimos ${checkedDate} días`
      case initialDate !== '' && finalDate !== today:
        return `Entre ${getFormattedDateWithoutTimezone(
          initialDate
        )} - ${getFormattedDateWithoutTimezone(finalDate)}`
      case initialDate !== '':
        return `Desde ${getFormattedDateWithoutTimezone(initialDate)}`
      default:
        return ''
    }
  }

  const holdingsText = () => {
    if (selectedFilters.holdings.size === 1) {
      const holdingSelected = holdings.find(
        (holding) => holding.id === [...selectedFilters.holdings][0]
      )
      return holdingSelected.name
    }
    return `${selectedFilters.holdings.size} holdings`
  }

  const companiesText = () => {
    if (selectedFilters.companies.size === 1) {
      return companies.find(
        (company) => company.companyId === [...selectedFilters.companies][0]
      ).name
    }

    return `${selectedFilters.companies.size} empresas`
  }

  const dynamicTags = [
    { name: 'holdings', text: holdingsText },
    { name: 'companies', text: companiesText }
  ]

  return (
    <div className="my-4 flex flex-wrap gap-x-1">
      <FilterTag filterText={datesText()} />
      {dynamicTags.map(
        (dynamicTag) =>
          selectedFilters[dynamicTag.name].size !== 0 && (
            <FilterTag
              key={dynamicTag.name}
              filterText={dynamicTag.text()}
              cleanTag={() => cleanTag(dynamicTag.name)}
            />
          )
      )}
    </div>
  )
}

export default FilterTags
