import styled from 'styled-components'

const TicketContainerWindowStyle = styled.div`
  .sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    max-height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 20;
    animation-duration: 1s;
    animation-name: fadeColorIn;
  }

  .hidden {
    display: none;
  }

  .sidebar-modal {
    --end-margin-left: calc(100% - (5 / 6 * 100%));

    position: fixed;
    top: 0;
    left: 0;
    margin-left: var(--end-margin-left);
    height: 100%;
    width: calc(5 / 6 * 100%);
    border-radius: 0.5rem 0 0 0.5rem;
    background-color: white;
    z-index: 30;
    animation-duration: 1s;
    animation-name: slideIn;

    @media (min-width: 1920px) {
      width: calc(5 / 6 * 100%);
    }
  }

  .fade-out {
    animation-name: fadeColorOut;
  }

  .slide-out {
    animation-name: slideOut;
  }

  @keyframes fadeColorIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @keyframes fadeColorOut {
    from {
      background-color: rgba(0, 0, 0, 0.3);
    }
    to {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  @keyframes slideIn {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: var(--end-margin-left);
    }
  }

  @keyframes slideOut {
    from {
      margin-left: var(--end-margin-left);
    }
    to {
      margin-left: 100%;
    }
  }
`

export default TicketContainerWindowStyle
