import React from 'react'

import VariationTag from './VariationTag'

const Total = ({ total, variation, positive }) => (
  <div className="mb-4 flex h-2/3">
    <div className="my-auto w-full text-center">
      <div className="mb-2 text-4xl font-semibold">{total}</div>
      <VariationTag variation={variation} positive={positive}/>
    </div>
  </div>
)

export default Total
