import React, { useState, useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Products from '../../components/Sale/Products'
import FileViewer from '../../components/shared/FileViewer'
import HeaderTitle from '../../components/shared/HeaderTitle'
import Loader from '../../components/shared/Loader'
import LoadingError from '../../components/shared/LoadingError'
import Modal from '../../components/shared/Modal/Modal'
import Chat from '../../components/Tickets/ManageTicket/Chat'
import EditTicketReview from '../../components/Tickets/ManageTicket/EditTicketReview'
import Header from '../../components/Tickets/ManageTicket/Header'
import { TrackingSearch, SaleSearch } from '../../components/Tickets/ManageTicket/TicketsLinks'
import TimeLine from '../../components/Tickets/ManageTicket/Timeline'
import UploadFile from '../../components/Tickets/ManageTicket/UploadFile'
import { photoLibrary, addCircle, chevron2 } from '../../constants/icons'
import ROUTES from '../../constants/routes'
import { DELIVERY_TITLES } from '../../constants/shippings'
import { TICKET_STATUSES } from '../../constants/tickets'
import { notificationContext } from '../../contexts/NotificationContext'
import { TicketsContext } from '../../contexts/TicketsContext'
import { findNotification, readNotificationHelper } from '../../helpers/notifications'
import { getSale, getCourierLogs } from '../../helpers/requests/sales'
import { getTicket } from '../../helpers/requests/tickets'
import useFetch from '../../hooks/useFetch'
import useNotifications from '../../hooks/useNotifications'
import Notes from '../../components/Tickets/ManageTicket/Notes'

const ManageTicket = ({ id: propId, isFromTickets = false }) => {
  const { id: paramId } = useParams()
  const [ticketId, setTicketId] = useState(paramId || propId)
  const [ticket, setTicket] = useState({})
  const { isLoading, error, refetch } = useFetch(getTicket, setTicket, ticketId)
  const [unreadMessages, setUnreadMessages] = useState(0)
  const [openUploader, setOpenUploader] = useState(false)
  const [filesResponse, setFilesResponse] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState([])
  const [filteredFiles, setFilteredFiles] = useState([])
  const [openFiles, setOpenFiles] = useState(false)
  const [associatedPackage, setAssociatedPackage] = useState(null)
  const [courierLogs, setCourierLogs] = useState([])

  const { push } = useHistory()

  const { ticketsInfo, ticketsMessages } = useContext(notificationContext)

  const {
    previousTicket,
    nextTicket,
    setActualTicket
  } = useContext(TicketsContext)

  const { readNotificationsAndUpdate } = useNotifications()

  useEffect(() => {
    if (filesResponse) {
      setSelectedFilter(filesResponse.filter(item => item.uploadedBy === 'ecommerce').length === 0 ? 'pinflag' : 'ecommerce')
      setFilteredFiles(
        filesResponse.filter(item => item.uploadedBy === 'ecommerce').length === 0 ?
        filesResponse.filter(item => item.uploadedBy === 'pinflag') :
        filesResponse.filter(item => item.uploadedBy === 'ecommerce')
      )
    }
  }, [filesResponse])

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const response = await getSale(ticket.package.packageId, {params: { origin: 'admin' }})
        setAssociatedPackage(response)
      } catch {
        // eslint-disable-next-line no-console
        console.log('no package')
      }
    }
    if (ticket && ticket.package && ticket.package.packageId) fetchPackage()
    return () => {
      setAssociatedPackage(null)
    }
  }, [ticket])

  useEffect(() => {
    const fetchCourierLogs = async () => {
      try {
        const logsData = await getCourierLogs(ticket.package.packageId)
        setCourierLogs(logsData)
      } catch {
        setCourierLogs([])
      }
    }
    if (ticket && ticket.package && ticket.package.packageId) fetchCourierLogs()
    return () => {
      setAssociatedPackage(null)
    }
  }, [ticket])

 useEffect(() => {
  if (ticket.id) {
    setActualTicket(ticket.id)
    setFilesResponse(ticket.TicketFiles)
    setUnreadMessages(ticketsMessages[ticket.id] ?? 0)
    const notificationAssociated = findNotification(ticketsInfo, ticket.id)
    if (notificationAssociated &&!notificationAssociated.read) {
      readNotificationsAndUpdate(
        [notificationAssociated.id],
        notificationAssociated.payload.category,
        notificationAssociated.idCategory || null
      )
    }
  }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [ticket.id])

  const handlePreviousButton = async () => {
    await readNotificationHelper(
      ticketsInfo,
      ticketId,
      readNotificationsAndUpdate
    )
    setTicketId(previousTicket)
  }

  const handleNextButton = async () => {
    await readNotificationHelper(
      ticketsInfo,
      ticketId,
      readNotificationsAndUpdate
    )
    setTicketId(nextTicket)
  }

  return (
    <div className="min-h-screen w-full overflow-y-auto bg-light-grey">
      {!isFromTickets && (
        <HeaderTitle
          title="Solicitudes"
          subtitle="Ver solicitud"
          goBack={() => push(ROUTES.TICKETS)}
        />
      )}
      <div className="m-8">
        {isLoading && (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        )}
        {error && <LoadingError />}
        {!isLoading && !error && (
          <>
            {isFromTickets && (
              <div className="flex flex-wrap justify-end gap-2 pb-6">
                <button
                  className={`${
                    !previousTicket
                      ? 'cursor-default border border-gray-300 opacity-70'
                      : 'border border-gray-300 bg-white hover:opacity-70'
                    } w-fit rounded py-1.5 px-3 text-xs`}
                  onClick={!previousTicket ? undefined : handlePreviousButton}
                  type="button"
                >
                  <div className="flex items-center px-1 py-2">
                    <img className="h-auto w-2.5" src={chevron2} alt="Anterior" />
                  </div>
                </button>
                <button
                  className={`${
                    !nextTicket
                      ? 'cursor-default border border-gray-300 opacity-70'
                      : 'border border-gray-300 bg-white hover:opacity-70'
                  } w-fit rounded py-1.5 px-3 text-xs`}
                  onClick={!nextTicket ? undefined : handleNextButton}
                  type="button"
                >
                  <div className="flex items-center px-1 py-2">
                    <img className="h-auto w-2.5 rotate-180" src={chevron2} alt="Siguiente" />
                  </div>
                </button>
              </div>
            )}
            <Header ticket={ticket} unreadMessages={unreadMessages}
            setUnreadMessages={setUnreadMessages} onTicketUpdated={() => refetch()}/>
            <div className="mt-4 flex gap-4 pb-10">
              <div className='flex w-1/2 flex-col gap-4'>
                <div className='flex flex-col gap-4 rounded-lg bg-white p-4'>
                  <EditTicketReview ticket={ticket} onTicketUpdated={() => refetch()} />
                </div>
                <div className='flex flex-col gap-4 lg:grid lg:grid-cols-2'>
                  <div>
                    {ticket.package && Object.keys(ticket.package).length > 0 && (
                    <div className="rounded-lg bg-white p-8">
                      <div className="text-base font-medium">Información de envío</div>
                      <div className="flex flex-col text-sm">
                        <div className="mt-4">
                          <div className="font-medium">Courier</div>
                          <div className="mt-2 text-medium-dark-grey">{ticket.package.courier}</div>
                        </div>
                        <div className="mt-4">
                          <div className="font-medium">Tipo de envío</div>
                          <div className="mt-2 text-medium-dark-grey">
                            {DELIVERY_TITLES[ticket.package.deliveryType]}
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="font-medium">O. de transporte</div>
                          {ticket.package.trackingNumber ? (
                            <div className="mt-2 text-medium-dark-grey">
                              <TrackingSearch
                                courier={ticket.package.courier}
                                trackingId={ticket.package.trackingNumber}
                              />
                            </div>
                          ) : (
                            <span className="mt-2 text-medium-dark-grey">-</span>
                          )}
                        </div>
                        <div className="mt-4">
                          <div className="font-medium">Tipo de servicio</div>
                          <div className="mt-2 text-medium-dark-grey">
                            {ticket.package.courierService}
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="font-medium">Package ID</div>
                          <div className="mt-2 text-medium-dark-grey">
                            <SaleSearch searchValue={ticket.package.packageId} customClass="w-fit"/>
                          </div>
                        </div>
                        <div className="row-span-2 mt-4">
                          <div className="font-medium">Dirección</div>
                          <div className="mt-2 text-medium-dark-grey">{ticket.package.address}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  </div>
                  <div className="col-span-1 flex flex-col gap-4 text-left text-sm">
                    <div className=" rounded-lg bg-white p-8">
                      <div className="text-base font-medium">Mensaje</div>
                      {ticket.description ? (
                        <div className="mt-2 whitespace-pre-wrap text-medium-dark-grey">{ticket.description}</div>
                      ) : (
                        <div className="mt-2 text-medium-dark-grey">-</div>
                      )}
                    </div>
                    <div className="rounded-lg bg-white">
                      <div className='flex w-full flex-row justify-between rounded-lg bg-white p-2'>
                        <div className="flex w-3/5 flex-row items-center gap-x-1">
                          <button
                            className={`flex w-fit flex-row rounded-md bg-transparent
                              py-2 pl-4 pr-1 text-sm
                              ${filesResponse && filesResponse.length > 0
                                ? 'cursor-pointer hover:opacity-70'
                                : 'cursor-default opacity-50'
                            }`}
                            onClick={() => {
                              if (filesResponse && filesResponse.length > 0) setOpenFiles(true)
                            }}
                            type="button"
                          >
                            <div className="flex flex-row items-center gap-x-1">
                              <img src={photoLibrary} alt="" className="my-auto w-7" />
                              <div className="font-medium">Ver archivos adjuntos</div>
                            </div>
                          </button>
                        </div>
                        <div className='flex w-2/5 flex-row items-center justify-end gap-x-1'>
                          <button
                          className="w-fit cursor-pointer rounded-md bg-transparent py-2 pr-4 text-sm text-pinflag hover:opacity-70"
                          onClick={() => setOpenUploader(true)}
                          type="button"
                          >
                            <div className='flex flex-row gap-x-1'>
                              <div className="flex items-center justify-center">
                                <img src={addCircle} alt="" className="my-auto w-5" />
                              </div>
                              <div className="font-medium">Agregar</div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col rounded-lg bg-white'>
                    <div className='h-max flex-1 overflow-auto pb-4'>
                      <div className='flex flex-col'>
                        <div className="flex flex-col">
                          <div className="ml-8 mt-4 text-base font-medium">Estado Ticket</div>
                          <div className="relative w-full">
                            <TimeLine
                              statusLogsLeft={ticket.TicketStatusLogs}
                              statusLogsRight={ticket.TicketStatusLogs}
                              isBothSides={false}
                            />
                          </div>
                        </div>
                        {associatedPackage && associatedPackage.packageHistory && (
                          <div className="flex flex-col">
                            <div className="ml-8 mt-4 text-base font-medium">
                              Tracking O. de Venta: {ticket.package.orderId}
                            </div>
                            <div className="relative w-full">
                              <TimeLine
                                statusLogsLeft={associatedPackage.packageHistory}
                                statusLogsRight={courierLogs}
                                isBothSides
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {associatedPackage &&
                  Object.keys(associatedPackage.products).length > 0 && (
                    <div className="rounded-lg bg-white p-8">
                      <div className="text-base font-medium">Compra</div>
                        <Products
                          products={associatedPackage.products}
                          shippingPaid={associatedPackage.shippingPaid ?
                            associatedPackage.shippingPaid : 0}
                          totalProductsPrice={associatedPackage.totalProductsPrice}
                        />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-1 flex w-1/2 flex-col gap-4 text-left text-sm">
                <div className="rounded-lg bg-white p-8">
                  <div className='flex w-1/2 flex-row items-center gap-x-3'>
                    <div className="text-lg font-medium">Chat</div>
                    {unreadMessages > 0 && (
                      <div className='rounded-full bg-red px-3 text-white'>
                      {unreadMessages} {unreadMessages === 1 ? 'mensaje nuevo' : 'mensajes nuevos'}
                      </div>
                    )}
                  </div>
                  <Chat
                    chatHistory={ticket.TicketChats}
                    ticketId={ticket.id}
                    unreadMessages={unreadMessages}
                    setUnreadMessages={setUnreadMessages}
                    isInDetail
                    isClosedTicket={TICKET_STATUSES.CLOSED.includes(ticket.status)}
                  />
                </div>
                <Notes
                  ticket={ticket}
                  onNoteCreated={() => refetch()}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {(filesResponse && filesResponse.length > 0 && filteredFiles.length > 0) && (
        <Modal title="Archivos adjuntos" show={openFiles} handleClose={() => setOpenFiles(false)}>
          <FileViewer
            files={filesResponse}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filteredFiles={filteredFiles}
            setFilteredFiles={setFilteredFiles}
            urlGetter={(object) => object?.fileUrl}
            filters={['ecommerce', 'pinflag']}
          />
        </Modal>
      )}
      {ticket && ticket.id && (
        <Modal title="Subir archivo" show={openUploader} handleClose={() => setOpenUploader(false)}>
          <UploadFile ticketId={ticket.id} setter={setFilesResponse} />
        </Modal>
      )}
    </div>
  )
}

export default ManageTicket
