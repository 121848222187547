import React, { createContext, useState, useMemo, useEffect } from 'react'

export const TicketsContext = createContext()

const TicketsProvider = ({ children }) => {
  const [allFilteredTickets, setAllFilteredTickets] = useState(null)
  const [allTickets, setAllTickets] = useState(null)
  const [actualTicket, setActualTicket] = useState(null)
  const [previousTicket, setPreviousTicket] = useState(null)
  const [nextTicket, setNextTicket] = useState(null)

  useEffect(() => {
    if (!actualTicket || !allFilteredTickets || !allFilteredTickets.length) {
      setPreviousTicket(null)
      setNextTicket(null)
      return
    }
    const index = allFilteredTickets.findIndex(id => id === actualTicket)

    const prev = index > 0 ? allFilteredTickets[index - 1] : null
    const next = index < allFilteredTickets.length - 1 ? allFilteredTickets[index + 1] : null

    setPreviousTicket(prev)
    setNextTicket(next)
  }, [actualTicket, allFilteredTickets])

  const value = useMemo(
    () => ({
      allFilteredTickets,
      setAllFilteredTickets,
      actualTicket,
      setActualTicket,
      previousTicket,
      setPreviousTicket,
      nextTicket,
      setNextTicket,
      allTickets,
      setAllTickets
    }),
    [actualTicket, allFilteredTickets, allTickets, nextTicket, previousTicket]
  )

  return (
    <TicketsContext.Provider value={value}>
      {children}
    </TicketsContext.Provider>
  )
}

export default TicketsProvider
