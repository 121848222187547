import apiClient from '../../axiosConfig/apiClient'

import { GET_COMPANIES_ALL, GET_PICKUP_SCHEDULES, HOLDINGS_URL, HOLDING_AGREEMENT_URL, COMPANIES_BY_HOLDING } from '../../constants/endpointUrls/api'

export const getHoldings = async () => {
  const response = await apiClient.get(HOLDINGS_URL)

  return response.data
}

export const getHoldingAgreements = async () => {
  const response = await apiClient.get(HOLDING_AGREEMENT_URL)

  return response.data
}

export const changeHoldingAgreement = (holdingId, condition) =>
  apiClient.post(HOLDING_AGREEMENT_URL, {
    holdingId,
    ...condition
  })

export const getPickUpSchedules = async () => {
  const response = await apiClient.get(GET_PICKUP_SCHEDULES)

  return response.data
}

export const getCompaniesData = async () => {
  const response = await apiClient.get(GET_COMPANIES_ALL)

  return response.data
}

export const getCompaniesByHoldingId = async (holdingId) => {
  const response = await apiClient.get(COMPANIES_BY_HOLDING(holdingId))

  return response.data
}
