import React from 'react'

import { COURIERS } from '../../../constants/couriers'
import { PRIORITY_OPTIONS, TYPE_OPTIONS } from '../../../constants/tickets'

const TicketsFilters = ({
  selectedType,
  setSelectedType,
  selectedCourier,
  setSelectedCourier,
  selectedPriority,
  setSelectedPriority,
  selectedReviewer,
  setSelectedReviewer,
  tickets
}) => {
  const reviewers = {}
  tickets.forEach(ticket => {
    if (ticket.reviewer?.email && ticket.reviewerId) {
      reviewers[ticket.reviewerId] = ticket.reviewer.email
    }
  })

  return (
    <div className="flex h-9 gap-3 text-sm">
      <select
        className="rounded-lg border p-2"
        value={selectedType}
        onChange={(e) => setSelectedType(e.target.value)}
      >
        <option value="">Tipo de Ticket</option>
        {Object.entries(TYPE_OPTIONS).map(([value, label]) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </select>
      <select
        className="rounded-lg border p-2"
        value={selectedPriority}
        onChange={(e) => setSelectedPriority(e.target.value)}
      >
        <option value="">Prioridad</option>
        {Object.entries(PRIORITY_OPTIONS).map(([value, label]) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </select>
      <select
        className="rounded-lg border p-2"
        value={selectedCourier}
        onChange={(e) => setSelectedCourier(e.target.value)}
      >
        <option value="">Courier</option>
        {COURIERS.map(courier => (
          <option key={courier} value={courier}>
            {courier.charAt(0).toUpperCase() + courier.slice(1)}
          </option>
        ))}
        <option value="Otros Couriers">Otros Couriers</option>
        <option value="Sin Courier">Sin Courier</option>
      </select>
      <select
        className="w-32 rounded-lg border p-2"
        value={selectedReviewer}
        onChange={(e) => setSelectedReviewer(e.target.value)}
      >
        <option value="">Asignado</option>
        {Object.entries(reviewers).map(([id, email]) => (
          <option key={id} value={id}>{email}</option>
        ))}
      </select>
    </div>
  )
}

export default TicketsFilters
