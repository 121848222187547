import React, { useState } from 'react'

import Button from '../../shared/Button'
import Loader from '../../shared/Loader'
import Modal from '../../shared/Modal/Modal'
import ResponseModal from '../../shared/Modal/ResponseModal'
import SelectionBar from '../../shared/SelectionBar/SelectionBar'
import SelectionButton from '../../shared/SelectionButton'
import { exportIcon, TICKET_ICONS } from '../../../constants/icons'
import { TICKET_STATUSES, TICKET_STATUSES_TO_CHANGE } from '../../../constants/tickets'
import { changeTicketsStatus, exportTickets } from '../../../helpers/requests/tickets'


const TicketsSelection = ({
  selectedTickets,
  handleCloseBar,
  isClosedTickets = false
}) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorResponse, setErrorResponse] = useState(false)
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [openStatusForm, setOpenStatusForm] = useState(false)
  const [closingMessage, setClosingMessage] = useState('')

  const parseBodyExport = () => {
    const ticketsIds = selectedTickets.map((packageId) => packageId)

    return { ticketsIds }
  }

  const exportSelectedTickets = async () => {
    if (selectedTickets.length === 0) return

    setIsLoadingExport(true)
    const body = parseBodyExport()

    try {
      await exportTickets(body)
    } catch (errorSubmit) {
      setErrorMessage(JSON.stringify(errorSubmit.response || errorSubmit))
      setErrorResponse(true)
    } finally {
      setIsLoadingExport(false)
      setResponseModalOpen(true)
    }
  }

  const handleClose = () => {
    setResponseModalOpen(false)
  }

  const parseBodyChangeStatus = () => {
    const ticketsIds = selectedTickets.map((ticketId) => ticketId)

    if (selectedStatus === TICKET_STATUSES.CLOSED[0]) {
      return { ticketsIds, closingMessage }
    }

    return { ticketsIds }
  }

  const changeSelectedTicketsStatus = async () => {
    if (selectedTickets.length === 0 || !selectedStatus) return

    setIsLoadingChangeStatus(true)
    const body = parseBodyChangeStatus()

    try {
      await changeTicketsStatus(body, selectedStatus)
    } catch (errorSubmit) {
      setErrorMessage(JSON.stringify(errorSubmit.response || errorSubmit))
      setErrorResponse(true)
    } finally {
      setOpenStatusForm(false)
      setIsLoadingChangeStatus(false)
      setResponseModalOpen(true)
    }
  }

  const changeStatusForm = () => (
    <form className="flex flex-col gap-4">
      <div className="mt-8 flex w-96 flex-col gap-2 rounded-lg bg-light-grey p-4">
        <label className="mb-2 block text-sm font-medium">Selecciona el mismo cambio de estado para todas</label>
        <div className="flex justify-center gap-6">
          <div className="flex flex-col gap-2">
            {selectedTickets.map((ticket) => (
              <p key={ticket}>Ticket ID: {ticket}</p>
            ))}
          </div>
          <div className="flex w-1/2 flex-col gap-2">
            <select
              className="h-10 w-full rounded-md bg-white p-2"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">Seleccionar estado</option>
              {Object.entries(TICKET_STATUSES_TO_CHANGE).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </select>
            {selectedStatus === TICKET_STATUSES.CLOSED[0] && (
              <input
                type="text"
                className="h-10 w-full rounded-md bg-white p-2"
                value={closingMessage}
                onChange={(e) => setClosingMessage(e.target.value)}
                placeholder="Mensaje de cierre"
              />
            )}
          </div>
        </div>

      </div>

      {errorMessage && <div className="text-red">{errorMessage}</div>}

      <div className="mt-4 flex justify-end gap-2">
        <Button
          onClick={() => setOpenStatusForm(false)}
          color="border border-pinflag-dark bg-white text-pinflag-dark"
        >
          Cancelar
        </Button>
        <Button
          onClick={changeSelectedTicketsStatus}
          color="bg-pinflag-dark"
        >
          {isLoadingChangeStatus ? (
            <Loader sizeClassname="h-4 w-4" borderColor="border-white" />
          ) : (
            'Guardar'
          )}
        </Button>
      </div>
    </form>
  )

  return (
    <>
      <SelectionBar
        amount={selectedTickets.length}
        text="Solicitudes"
        color="bg-white"
        handleClose={handleCloseBar}
      >
        {!isClosedTickets && (
          <SelectionButton
            text={
              <>
                <p>Cambiar</p>
                <p>estado</p>
            </>
          }
          icon={TICKET_ICONS.switch}
          handleClick={() => setOpenStatusForm(true)}
            isLoading={isLoadingChangeStatus}
          />
        )}
        <SelectionButton
          text={
            <>
              <p>Exportar</p>
              <p>Solicitudes</p>
            </>
          }
          icon={exportIcon}
          handleClick={exportSelectedTickets}
          isLoading={isLoadingExport}
        />
      </SelectionBar>
      <Modal
        show={openStatusForm}
        handleClose={() => setOpenStatusForm(false)}
        title="Cambio de estado"
      >
        {changeStatusForm()}
      </Modal>
      <ResponseModal
        handleClose={errorResponse ? handleClose : () => window.location.reload()}
        show={responseModalOpen}
        error={errorMessage}
      />
    </>
  )
}

export default TicketsSelection
