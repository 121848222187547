import React, { useState } from 'react'

import { closeTicket } from '../../../helpers/requests/tickets'
import Button from '../../shared/Button'
import Loader from '../../shared/Loader'

const ChangeResolvedStatus = ({ ticketId, onTicketUpdated, setOpenChangeStatus }) => {
  const [closingMessage, setClosingMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorChangeStatus, setErrorChangeStatus] = useState('')

  const changeStatus = async () => {
    setLoading(true)
    try {
      await closeTicket(ticketId, { closingMessage })
      onTicketUpdated()
      setOpenChangeStatus(false)
    } catch (err) {
      if (err.response) setErrorChangeStatus(err.response.data.message)
    }
    setLoading(false)
  }

  return (
    <div className="m-2 flex flex-col p-4">
      <div>
        <div className='text-sm'>Estado:</div>
        <div className='mt-1 block w-full rounded-lg border border-pinflag bg-light-pinflag p-2.5 text-sm'>Resuelto</div>
      </div>
        <div className="mt-6 w-full self-center text-start">
          <p className="text-sm">Mensaje de cierre:</p>
          <textarea
            className="mt-2 h-3/6 w-full rounded-sm border border-gray-300 p-2 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
            onChange={(event) => setClosingMessage(event.target.value)}
            maxLength={2500}
          />
        </div>
      {errorChangeStatus && <div className="self-center text-sm text-red">{errorChangeStatus}</div>}
      <div className="mt-5 self-end">
        {loading ? (
          <Loader />
        ) : (
          <Button color="bg-pinflag" onClick={changeStatus}>
            Cambiar estado
          </Button>
        )}
      </div>
    </div>
  )
}

export default ChangeResolvedStatus
