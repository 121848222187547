import React from 'react'

const VariationTag = ({ variation, positive }) => (
  <div className="mb-4 flex w-full justify-center">
    <div className="w-fit rounded p-0.5 text-base">
      {positive && '+'}{variation}%
    </div>
  </div>
)

export default VariationTag
