import React from 'react'

const Card = ({ title, image, children }) => (
  <div className="flex w-full min-w-[15%] flex-col justify-center rounded-xl border bg-white p-2 py-6 pb-12">
    <div className="mx-4 mb-4 flex items-center justify-center gap-4">
      {image && <img src={image} alt="ticket icon" className="h-5" />}
      {title && <div className="text-sm font-medium">{title}</div>}
    </div>
    {children}
  </div>
)

export default Card
