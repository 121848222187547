
import React from 'react'

import { linkIcon } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'
import { PRIORITY_COLOR } from '../../../constants/tickets'

const TicketTableRow = ({ items, rowId, isSelected, excludeClickIndexes,
  openTicketHandler, onRowSelect, priority, isClosedTickets }) => {

    const rawColor = isSelected ? 'bg-blue-100/40' : ''
    const borderColor = PRIORITY_COLOR[priority] || ''

    return (
      <tr className={`w-full ${rawColor}`}>
        <td className='relative w-8'>
          {!isClosedTickets && (
            <div
              className="absolute left-0 top-0 h-full w-1 rounded-r-full"
              style={{ backgroundColor: borderColor }}
            />
          )}
        </td>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <td key={index} className="whitespace-nowrap py-1 pr-1 text-left">
            <span className="inline-flex items-center">
              {index === 1 && (
                <a
                  href={`${ROUTES.TICKETS}/${rowId}`}
                  className="mr-2 inline-flex underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => onRowSelect(rowId)}
                >
                  <img src={linkIcon} alt="link" className="w-3" />
                </a>
              )}
              {!excludeClickIndexes.includes(index) ? (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <span
                  className="block cursor-pointer p-0"
                  onClick={() => openTicketHandler(rowId)}
                >
                  {item || '-'}
                </span>
              ) : (
                item || '-'
              )}
            </span>
          </td>
        ))}
      </tr>
    )
}

export default TicketTableRow
