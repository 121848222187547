import React, { useState } from 'react'

import { getFormattedDateWithoutTimezone, substractDate } from '../../../utils/dates'

import Filters from './Filters'
import TicketsStats from './TicketsStats'

const TicketDashboard = () => {
  const [currentFilters, setCurrentFilters] = useState(
    `?startDate=${getFormattedDateWithoutTimezone(substractDate(new Date(), 30))}`
  )

  return (
    <div>
      <Filters setFilters={setCurrentFilters} />
      <TicketsStats currentFilters={currentFilters} />
    </div>
  )
}

export default TicketDashboard
