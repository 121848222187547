import React, { useState, useContext, useEffect } from 'react'

import { chatNotification, chatEmpty } from '../../constants/icons'
import { STATUS_COLOR, STATUS_ICONS, TICKET_STATUSES } from '../../constants/tickets'
import { notificationContext } from '../../contexts/NotificationContext'
import { countNewMessagesByTicketId } from '../../helpers/notifications'
import useLocalStorage from '../../hooks/useLocalStorage'
import { getFormattedDate } from '../../utils/dates'
import Button from '../shared/Button'
import Table from '../shared/Table/Table'

import ChatContainerWindow from './ManageTicket/ChatContainerWindow'
import TicketContainerWindow from './ManageTicket/TicketContainerWindow'
import TicketTableRow from './ManageTicket/TicketTableRow'

const TicketsTable = ({
  tickets,
  handleSort,
  isLoading,
  error,
  selectedTickets,
  setSelectedTickets,
  isClosedTickets = false
}) => {
  const [ticketId, setTicketId] = useState('')
  const [openChat, setOpenChat] = useState(false)
  const [openTicketId, setOpenTicketId] = useLocalStorage('openTicketId', null)
  const [openTicket, setOpenTicket] = useState(false)

  const { ticketsMessages, setTicketsMessages, ticketsInfo } = useContext(notificationContext)

  const openChatHandler = (id) => {
    setTicketId(id)
    setOpenChat(true)
    setOpenTicketId(id)
  }

  const openTicketHandler = (id) => {
    setTicketId(id)
    setOpenTicket(true)
    setOpenTicketId(id)
  }

  const handleRowSelection = (id) => {
    setOpenTicketId(id)
  }

  useEffect(() => {
    if (ticketsInfo?.notifications) {
      setTicketsMessages(prevMessages =>
        countNewMessagesByTicketId(ticketsInfo.notifications, prevMessages, ticketId, false)
      )
    }
  }, [ticketsInfo?.notifications, setTicketsMessages, ticketId])

  const checkAll = Object.keys(selectedTickets).length === Math.min(tickets.length)

  const handleCheckAll = () => {
    if (!checkAll) {
      setSelectedTickets(tickets.map((ticket) => {
        if (ticket.errorMessage) return null

        return ticket.id
      }).filter(Boolean))
    } else {
      setSelectedTickets([])
    }
  }

  const isChecked = (ticket) => selectedTickets.includes(ticket.id)

  const changeSelected = (ticket) => {
    if (!isChecked(ticket)) {
      setSelectedTickets((prevSelected) => [...prevSelected, ticket.id])
    } else {
      setSelectedTickets(selectedTickets.filter((item) => item !== ticket.id))
    }
  }

  if (!isLoading && !error && tickets.length === 0) {
    return (
      <div className="mx-10 border-x border-b bg-white py-8 text-center text-s">
        No hay tickets con este criterio
      </div>
    )
  }

  const sortValues = {
    'O. de venta': { key: 'orderId', type: 'string' },
    Estado: { key: 'status', type: 'string' },
    ID: { key: 'id', type: 'number' },
    Fecha: { key: 'createdAt', type: 'date' },
    Holding: { key: 'holdingName', type: 'string' },
    Motivo: { key: 'type', type: 'string' },
    Courier: { key: 'courier', type: 'string' },
    Actualización: { key: 'updatedAt', type: 'date' }
  }

  return (
    <>
      <Table
        maxHeight="max-h-[66vh]"
        sortValues={sortValues}
        sortData={handleSort}
        headers={[
          <input type="checkbox" checked={checkAll} onChange={handleCheckAll} />,
          'O. de venta',
          'Estado',
          'ID',
          'Fecha',
          'Holding',
          'Motivo',
          'Courier',
          'Actualización',
          'Chat'
        ]}
        isLoading={isLoading}
        error={error}
      >
        {tickets?.map((ticket) => (
          <TicketTableRow
            key={ticket.id}
            items={[
              <input type="checkbox" checked={isChecked(ticket)} onChange={() => changeSelected(ticket)} />,
              ticket.orderId,
              <div className={`flex w-max gap-2 rounded py-1 px-2 ${STATUS_COLOR[ticket.status]}`}>
                <img
                  src={STATUS_ICONS[ticket.status]}
                  alt={ticket.status}
                  className={`my-auto h-auto ${ticket.status === TICKET_STATUSES.CLOSED_AUTOMATICALLY[0] ? 'w-4' : 'w-2.5'}`}
                />
                {ticket.status}
              </div>,
              ticket.id,
              getFormattedDate(ticket.createdAt),
              ticket.holdingName,
              ticket.type.split('/')[1].trim(),
              ticket.courier || '-',
              getFormattedDate(ticket.updatedAt),
              (ticketsMessages[ticket.id] || 0) > 0 ? (
                <Button
                  small
                  onClick={() => {openChatHandler(ticket.id)
                }}
                >
                  <img src={chatNotification} alt="chat" className="h-6 py-1" />
                </Button>
              ) : (
                <Button
                  small
                onClick={() => {openChatHandler(ticket.id)
                }}
              >
                <img src={chatEmpty} alt="chat" className="h-6 py-1" />
                </Button>
              )
            ]}
            rowId={ticket.id}
            openTicketHandler={openTicketHandler}
            isSelected={openTicketId === ticket.id}
            onRowSelect={handleRowSelection}
            excludeClickIndexes={[0, 9]}
            priority={ticket.priority}
            isClosedTickets={isClosedTickets}
          />
        ))}
      </Table>
      {openChat && (
        <ChatContainerWindow
          ticketId={ticketId}
          openChat={openChat}
          setOpenChat={setOpenChat}
          unreadMessages={ticketsMessages[ticketId] || 0}
        />
      )}
      {openTicket && (
        <TicketContainerWindow
          ticketId={ticketId}
          openTicket={openTicket}
          setOpenTicket={setOpenTicket}
        />
      )}
    </>
  )
}

export default TicketsTable
