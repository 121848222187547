import React, { useState, useCallback } from 'react'

import { DEVICES } from '../../../constants/general'
import { getHoldings } from '../../../helpers/requests/companies'
import useFetch from '../../../hooks/useFetch'
import { getFormattedDateWithoutTimezone, substractDate } from '../../../utils/dates'
import CheckGeneral from '../../shared/CheckFilters/CheckGeneral'
import DropDown from '../../shared/DropDown'

import DateFilter from './DateFilter'
import FilterTags from './FilterTags'

const Filters = ({
  setFilters
}) => {
  const today = new Date().toLocaleDateString('en-CA')
  const [initialDate, setInitialDate] = useState(substractDate(new Date(), 30))
  const [finalDate, setFinalDate] = useState(today)
  const [checkedDate, setCheckedDate] = useState('30')
  const [holdings, setHoldings] = useState([])
  const [companies, setCompanies] = useState([])
  const [holdingsState, setHoldingsState] = useState([])

  const [companyState, setCompanyState] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({
    holdings: new Set(),
    companies: new Set()
  })
  const [hasChanged, setHasChanged] = useState(false)

  const filterSetters = {
    holdings: { setter: setHoldingsState, optionSize: holdings.length }
  }

  const holdingSetter = useCallback((data) => {
    const sortedHoldings = [...data].sort((a, b) => a.name.localeCompare(b.name))
    setHoldings(sortedHoldings)
    setHoldingsState(new Array(data.length).fill(false))
  }, [])

  useFetch(getHoldings, holdingSetter)

  const isChecked = (checkedStates) => checkedStates.some((value) => value)

  const isDateSelected = () => {
    if (initialDate !== '' || finalDate !== today) return true
    return false
  }

  const handleEraseFilters = () => {
    setInitialDate(substractDate(new Date(), 30))
    setCheckedDate('30')
    setFinalDate(today)
    for (const filterSetter of Object.values(filterSetters)) {
      filterSetter.setter(new Array(filterSetter.optionSize).fill(false))
    }
    setSelectedFilters({
      holdings: new Set(),
      companies: new Set()
    })
    setHasChanged(true)
  }

  const assembleFilter = (currentFilter, name, type, checkedStatus) => {
    if (selectedFilters[type].size > 0 && !checkedStatus.every((value) => value)) {
      if (currentFilter.length > 1) {
        return currentFilter.concat(`&${name}=`, [...selectedFilters[type]].join(','))
      }
      return currentFilter.concat(`${name}=`, [...selectedFilters[type]].join(','))
    }
    return currentFilter
  }

  const handleFilter = () => {
    let filters = '?'
    if (initialDate !== '') {
      filters = filters.concat('startDate=', getFormattedDateWithoutTimezone(initialDate))
    }
    if (finalDate !== today) {
      filters = filters.concat('&finishDate=', getFormattedDateWithoutTimezone(finalDate))
    }
    filters = assembleFilter(filters, 'holdingId', 'holdings', holdingsState)
    filters = assembleFilter(filters, 'companyId', 'companies', companyState)
    setFilters(filters)
    setHasChanged(false)
  }

  const cleanAFilter = (name) => {
    setSelectedFilters({
      ...selectedFilters,
      [name]: new Set()
    })
    filterSetters[name].setter(new Array(filterSetters[name].optionSize).fill(false))
    setHasChanged(true)
  }

  const handleHoldingChange = (updatedChecks) => {
    setHoldingsState(updatedChecks)

    if (isChecked(updatedChecks)) {
      const selectedHoldings = holdings.filter((_, index) => updatedChecks[index])
      const companiesHoldings = selectedHoldings.map((holding) => holding.companies).flat()
      setCompanyState(new Array(companiesHoldings.length).fill(false))
      setCompanies(companiesHoldings)
    } else {
      setCompanies([])
    }
  }

  return (
    <div>
      <div className="my-4 flex flex-wrap">
        <DropDown name="Fecha" isChecked={isDateSelected()}>
          <DateFilter
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            today={today}
            checked={checkedDate}
            setChecked={setCheckedDate}
            setHasChanged={setHasChanged}
          />
        </DropDown>
        <DropDown name="Holding" isChecked={isChecked(holdingsState)}>
          <CheckGeneral
            optionsCheck={holdings}
            setCheckState={handleHoldingChange}
            checkState={holdingsState}
            setSelected={setSelectedFilters}
            selected={selectedFilters}
            setHasChanged={setHasChanged}
            type="holdings"
            keyName="id"
          />
        </DropDown>
        <button
          className={`ml-2 rounded-lg px-6 text-sm text-white ${
            hasChanged ? 'bg-pinflag hover:opacity-70' : 'cursor-default bg-medium-gray'
          }`}
          type="button"
          onClick={hasChanged ? handleFilter : undefined}
        >
          Filtrar
        </button>
        <button
          className="ml-3 rounded-lg border border-pinflag px-6 text-sm text-pinflag hover:opacity-70"
          type="button"
          onClick={handleEraseFilters}
        >
          Limpiar filtros
        </button>
      </div>
      <FilterTags
        checkedDate={checkedDate}
        initialDate={initialDate}
        finalDate={finalDate}
        selectedFilters={selectedFilters}
        holdings={holdings}
        devices={DEVICES}
        cleanTag={cleanAFilter}
        companies={companies}
      />
    </div>
  )
}

export default Filters
