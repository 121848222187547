import React, { useState, useContext, useEffect } from 'react'
import { PieChart, Pie, Legend, Tooltip } from 'recharts'

import { TICKET_ICONS, timeClock, ticket } from '../../../constants/icons'
import { TicketsContext } from '../../../contexts/TicketsContext'
import { filterTickets, countOpenTickets, averageResponseTime, countPendingTickets,
  averageCloseTicket, getMonthRangeStats, getPreviousPeriod, getStatsValues,
  processTicketTypes, processClientStats } from '../../../helpers/processStats/TicketsStats'
import Loader from '../../shared/Loader'

import Card from './Card'
import PieToolTip from './PieToolTip'
import Total from './Total'

const PieLegend = (...args) => <span className="text-sm text-default-font">
    {args[1].payload.payload.payload.name} {args[1].payload.payload.payload.percentage}%{' '}
    {args[1].payload.payload.payload.difference}
  </span>

const TicketsStats = ({ currentFilters }) => {
  const [openTickets, setOpenTickets] = useState(null)
  const [pendingTickets, setPendingTickets] = useState(null)
  const [averageTickets, setAverageTickets] = useState(null)
  const [responseTime, setResponseTime] = useState(null)
  const [closeTime, setCloseTime] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [month, setMonth] = useState(null)
  const [actualPeriodTickets, setActualPeriodTickets] = useState(null)
  const [previousPeriodTickets, setPreviousPeriodTickets] = useState(null)
  const [ticketTypes, setTicketTypes] = useState(null)
  const [clientStats, setClientStats] = useState(null)

  const { allTickets } = useContext(TicketsContext)

  useEffect(() => {
    setIsLoading(true)
    const filteredTickets = filterTickets(allTickets, currentFilters)
    const previousPeriod = getPreviousPeriod(currentFilters)
    const filteredPreviousTickets = filterTickets(allTickets, previousPeriod)
    setActualPeriodTickets(filteredTickets)
    setPreviousPeriodTickets(filteredPreviousTickets)
    setMonth(getMonthRangeStats(currentFilters))
  }, [currentFilters, allTickets])

  useEffect(() => {
    if (actualPeriodTickets !== null && previousPeriodTickets !== null) {
      setOpenTickets(getStatsValues(actualPeriodTickets, previousPeriodTickets, countOpenTickets))
      setPendingTickets(getStatsValues(actualPeriodTickets, previousPeriodTickets,
        countPendingTickets))
      setAverageTickets(getStatsValues(actualPeriodTickets, previousPeriodTickets,
        (tickets) => tickets.length))
      setResponseTime(getStatsValues(actualPeriodTickets, previousPeriodTickets,
        averageResponseTime))
      setCloseTime(getStatsValues(actualPeriodTickets, previousPeriodTickets, averageCloseTicket))
      setTicketTypes(processTicketTypes(actualPeriodTickets, previousPeriodTickets))
      setClientStats(processClientStats(actualPeriodTickets))
      setIsLoading(false)
    }
  }, [actualPeriodTickets, previousPeriodTickets])

  if (isLoading) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="my-4 flex flex-wrap gap-5 lg:grid lg:grid-cols-5">
        {openTickets && (
          <Card title="Tickets abiertos" image={ticket}>
            <Total
              total={openTickets.actual}
              variation={openTickets.difference}
              positive={openTickets.difference >= 0 || openTickets.difference === '∞'}
            />
            <div className="text-center text-xl font-normal">{`${openTickets.actual}/${averageTickets.actual}`}</div>
          </Card>
        )}
        {pendingTickets && (
          <Card title="Tickets pendientes de respuesta" image={ticket}>
            <Total
              total={pendingTickets.actual}
              variation={pendingTickets.difference}
              positive={pendingTickets.difference >= 0 || pendingTickets.difference === '∞'}
            />
            <div className="text-center text-xl font-normal">{`${pendingTickets.actual}/${averageTickets.actual}`}</div>
          </Card>
        )}
        {averageTickets && (
          <Card title="Tickets totales" image={TICKET_ICONS.average}>
            <Total
              total={averageTickets.actual}
              variation={averageTickets.difference}
              positive={averageTickets.difference >= 0 || averageTickets.difference === '∞'}
            />
            <div className="text-center text-xl font-normal">{month}</div>
          </Card>
        )}
        {responseTime && (
          <Card title="Tiempo promedio primera respuesta" image={timeClock}>
            <Total
              total={`${responseTime.actual} hrs`}
              variation={responseTime.difference}
              positive={responseTime.difference >= 0 || responseTime.difference === '∞'}
            />
            <div className="text-center text-xl font-normal">{month}</div>
          </Card>
        )}
        {closeTime && (
          <Card title="Tiempo promedio cierre solicitud" image={timeClock}>
            <Total
              total={`${closeTime.actual} hrs`}
              variation={closeTime.difference}
              positive={closeTime.difference >= 0 || closeTime.difference === '∞'}
            />
            <div className="text-center text-xl font-normal">{month}</div>
          </Card>
        )}
      </div>
      <div className="my-4 flex flex-wrap gap-5 lg:grid lg:grid-cols-2">
        <div className="flex h-[350px]  w-full min-w-[15%] flex-col rounded-xl border bg-white">
          <div className="mx-10 mt-12 border-b-2 pb-2 text-left text-xl font-medium">Tipos de Tickets</div>
          {ticketTypes && (
            <div className="flex h-[250px] justify-start ">
              <PieChart width={550} height={250}>
                <Legend
                  iconType="circle"
                  layout="vertical"
                  verticalAlign="middle"
                  align="right"
                  iconSize={6}
                  formatter={PieLegend}
                />
                <Tooltip content={<PieToolTip />} cursor={false} />
                <Pie data={ticketTypes} outerRadius={80} dataKey="value"/>
              </PieChart>
            </div>
          )}
        </div>
        <div className="flex h-full w-full min-w-[15%] flex-col rounded-xl border bg-white">
          <div className="mx-10 mt-12 border-b-2 pb-2 text-left text-xl font-medium">Tickets por cliente</div>
          <div className="mx-10 my-6 flex flex-col gap-4">
            {clientStats && clientStats.map(client => (
              <div key={client.name} className="flex flex-col gap-1">
                <div className="flex w-full justify-between text-sm text-gray-600">
                  <span>{client.name}</span>
                  <span>{client.count}</span>
                </div>
                <div className="flex items-center gap-3">
                  <div className="relative h-6 grow rounded-md bg-gray-200">
                    <div
                      className="absolute h-6 rounded-md bg-[#40E0D0]"
                      style={{ width: `${(client.count / actualPeriodTickets.length) * 100}%` }}
                    />
                  </div>
                </div>
              </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketsStats
