import React from 'react'

const SelectionBar = ({ amount, text, color, children, handleClose }) => (
  <div
    className={`${
      amount !== 0 ? `flex w-fit ${color} my-5 rounded-lg pl-6 shadow-xl` : 'invisible h-1'
    }`}
  >
    <div className="mr-4 flex">
      {amount !== 0 && (
        <div className="flex flex-col justify-center self-center">
          <div className="mt-1.5 flex items-center justify-center self-center rounded-md bg-ultra-dark-grey py-1 px-2 text-xs font-semibold text-white">
            {amount}
          </div>
          <div className="flex py-0.5 text-xs text-ultra-dark-grey">{text}</div>
        </div>
      )}
    </div>
    <div className="w-px bg-ultra-dark-grey" />
    {children}
    {handleClose && (
      <>
        <div className="w-px bg-ultra-dark-grey" />
        <button className="my-auto mx-2 p-1 text-xl font-light" type="button" onClick={handleClose}>
          x
        </button>
      </>
    )}
  </div>
)

export default SelectionBar
